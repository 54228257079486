<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Grant Cycle A Reviews
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h1 class="heading-title">
                        Karin Unruh - Bondurant Elementary STEAM Program
                      </h1>
                      <h4>Relevance to RFP</h4>
                      <p>
                        Karin’s grant proposal seems to be highly relevant to
                        the RFP that you're responding to. You have done an
                        excellent job of outlining the goals and objectives of
                        your proposed research, and have made a clear case for
                        how your work aligns with the priorities of the funding
                        agency. It's clear that you've put a lot of thought into
                        tailoring your proposal to meet the specific
                        requirements of the RFP.
                      </p>
                      <h4>Abstract</h4>
                      <p>
                        Karin’s abstract is clear and concise, and effectively
                        summarizes your proposal's key points. You've done a
                        good job of highlighting the main objectives of your
                        research, and have provided a compelling rationale for
                        why this work is important. Your abstract sets the stage
                        nicely for the rest of your proposal.
                      </p>
                      <h4>Proposal Narrative</h4>
                      <p>
                        Karin’s proposal narrative is well-written and engaging.
                        You've done an excellent job of providing a detailed
                        overview of your research goals and methods, and have
                        included a thorough literature review to support your
                        work. Your proposal narrative is well-organized and easy
                        to follow, which makes it easy for the reader to
                        understand the scope and significance of your proposed
                        research.
                      </p>
                      <h4>Budget</h4>
                      <p>
                        Karin’s budget is well-constructed and realistic. You've
                        provided a detailed breakdown of your expenses, and have
                        done a good job of justifying each item in your budget.
                        Your budget is well-aligned with the scope and scale of
                        your research, and you've made sure to include all of
                        the necessary expenses to ensure the success of your
                        project.
                      </p>
                      <h4>Broader Impacts Section</h4>
                      <p>
                        Karin’s broader impacts section is strong and
                        well-developed. You've done an excellent job of
                        articulating how your research will benefit society more
                        broadly, and have included specific outreach and
                        engagement activities to promote broader impacts. Your
                        commitment to public engagement is clear, and you've
                        thoughtfully considered how your work will impact
                        various stakeholders.
                      </p>
                      <h4>Overall Proposal</h4>
                      <p>
                        Overall, Karin’s grant proposal is well-crafted and
                        effectively communicates the significance of your
                        proposed research. You've done a good job aligning your
                        work with the RFP requirements and provided a compelling
                        rationale for why your work is important. Your proposal
                        narrative is clear and well-organized, and your budget
                        and broader impacts sections are both strong. While
                        there are always areas for improvement in any proposal,
                        Karin’s proposal seems to be a solid effort that has the
                        potential to make a meaningful contribution to the
                        field.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h1 class="heading-title">
                        ​William Heppding - Spencer Dissertation Fellowship-
                        Distance Education Research
                      </h1>
                      <h4>Relevance to RFP</h4>
                      <p>
                        William’s grant proposal is highly relevant to the RFP.
                        You have successfully addressed the primary selection
                        criteria and have provided a well thought out research
                        question that has significant implications for education
                        in light of the COVID-19 pandemic. You have taken care
                        to differentiate between emergency remote learning and
                        authentic distance learning and have identified the
                        importance of understanding how distance learning has
                        been impacted by COVID-19. Your proposal aligns
                        perfectly with the RFP and is a strong candidate for the
                        Spencer Dissertation Fellowship Program.
                      </p>
                      <h4>Abstract</h4>
                      <p>
                        William’s abstract does an excellent job summarizing
                        your proposal's key points. You have provided a clear
                        and concise overview of your research question,
                        highlighting the importance of understanding the impact
                        of COVID-19 on distance learning. You have also
                        identified the need to differentiate between emergency
                        remote learning and authentic distance learning and have
                        outlined your proposed research approach.
                      </p>
                      <h4>Proposal Narrative</h4>
                      <p>
                        William’s proposal narrative is well-written and clearly
                        explains your research question, proposed methodology,
                        and expected outcomes. You have identified the
                        importance of considering the views of students,
                        teachers, and administrators regarding distance learning
                        prior to the pandemic and have proposed a sample size
                        that is appropriate for the scope of your research. You
                        have also identified your interest in distance education
                        and explained how your research would further your
                        understanding of this field.
                      </p>
                      <h4>Budget</h4>
                      <p>
                        Although William did not include a budget in your
                        proposal, you have identified the feasibility of your
                        research and have proposed a sample size that is
                        appropriate for your research question. This
                        demonstrates that you have considered the costs
                        associated with your research and have identified a
                        feasible approach to conducting your study.
                      </p>
                      <h4>Broader Impacts Section</h4>
                      <p>
                        William’s proposal has significant broader impacts,
                        particularly in light of the COVID-19 pandemic. Your
                        research can inform distance learning practices, and
                        help schools and educators get the most out of this form
                        of education. Your research could also have broader
                        implications for the use of technology in education and
                        could inform future policies and practices.
                      </p>
                      <h4>Overall Proposal</h4>
                      <p>
                        William's grant proposal is well-written and addresses
                        the primary selection criteria for the NAEd/Spencer
                        Dissertation Fellowship Program. However, one area that
                        could be improved upon is the inclusion of a more
                        detailed budget. While William mentions the need to
                        limit the study's sample size and potentially focus on a
                        specific department, he does not provide specific
                        budgetary figures or a breakdown of how the funds will
                        be allocated. Providing a more detailed budget can
                        strengthen the proposal and give reviewers a clearer
                        understanding of the project's scope and feasibility.
                      </p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5870-3"
                          >Back to LDTE 5870</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
